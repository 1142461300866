import React from 'react'
import './Contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {FiTwitter} from 'react-icons/fi'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_k1o2s28', 'template_3x23k6k', form.current, 'M9t9-RrlM7ZJVvOiG')
    
    e.target.reset()
  };
    

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact_container'>
        <div className='contact_options'>
          <article className='contact_option'>
            <MdOutlineEmail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>yaningw.96@gmail.com</h5>
            <a href="mailto:yaningw.96@gmail.com" target='_blank'>Send a message</a>
          </article>
          <article className='contact_option'>
            <RiMessengerLine className='contact_option-icon'/>
            <h4>Messenger</h4>
            <h5>Yaning Wang</h5>
            <a href="https://m.me/yaning.wang.186/" target='_blank'>Send a message</a>
          </article>
          <article className='contact_option'>
            <FiTwitter className='contact_option-icon'/>
            <h4>Twitter</h4>
            <h5>@YawningW</h5>
            <a href="https://twitter.com/YawningW" target='_blank'>Send a message</a>
          </article>
          </div>
        {/* END OF CONTACT OPTION */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="email" name='email' placeholder='Your Email' required/>
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact