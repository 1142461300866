import React from 'react'
import './Footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'
import {FiTwitter} from 'react-icons/fi'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'>Yaning</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">about</a></li>
        <li><a href="#experience">experience</a></li>
        <li><a href="#services">services</a></li>
        <li><a href="#portfolio">portfolio</a></li>
        <li><a href="#testimonials">testimonials</a></li>
        <li><a href="#contact">contact</a></li>
      </ul>

      <div className="footer_socials">
        <a href="https://www.facebook.com/yaning.wang.186/"><FaFacebookF/></a>
        <a href="https://www.instagram.com/yaning_wang/"><BsInstagram/></a>
        <a href="https://twitter.com/YawningW"><FiTwitter/></a>
      </div>

      <div className="footer_copywrite">
        <small>&copy; YaningWang Portfolio Page. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer