import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGitlab} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'


const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href='https://linkedin.com/in/YWang00' target='_blank'><BsLinkedin/></a>
        <a href='https://gitlab.com/thtguy00' target='_blank'><FaGitlab/></a>
        <a href='https://www.instagram.com/' target='_blank'><BsInstagram/></a>
    </div>
  )
}

export default HeaderSocials